<template>
  <div id="bottomRight">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color: #5cd9e8">
          <icon name="chart-area"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">{{park_space_pic.title}}</span>
          <div class="decoration2">
            <dv-decoration-2 :reverse="true" style="width: 5px; height: 4.5rem"  />
          </div>
        </div>
      </div>
      <div style="height:95%">
        <BottomRightChart :cdata="park_space_pic.cdata" />
      </div>
    </div>
  </div>
</template>

<script>
import BottomRightChart from "@/components/echart/bottom/bottomRightChart";
export default {
  data() {
    return {};
  },
  props: {
    park_space_pic: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    BottomRightChart,
  },
  mounted() {
    // console.log(this.park_space_pic,"park_space_pic")

  },
  methods: {},
};
</script>

<style lang="scss">
#bottomRight {
  padding: 0.2rem 0.2rem 0;
  height: 100%;//6.5rem;
  min-width: 3.75rem;
  border-radius: 0.0625rem;
  .bg-color-black {
    height: 95%;//6.1875rem;
    border-radius: 0.125rem;
  }
  .text {
    color: #c3cbde;
  } //下滑线动态
  .decoration2 {
    position: absolute;
    right: 0.125rem;
  }
  .chart-box {
    margin-top: 0.2rem;
    width: 2.125rem;
    height: 2.125rem;
    .active-ring-name {
      padding-top: 0.125rem;
    }
  }
}
</style>