<template>
  <div id="centerRight1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color:#5cd9e8">
          <icon name="chart-line"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">车位使用情况</span>
        </div>
      </div>
      <div class="d-flex jc-center body-box">
		  <!-- width:3.375rem; -->
        <dv-scroll-board :config="config" style="width:5.2rem;height:4.28rem" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // config: {
      //   header: ["停车区位", "车位数", "剩余车位","收入"],
      //   data: [
      //     ["城厢区", "1000", "500","1276"], //<span  class='colorGrass'>↑75%</span>
      //     ["荔城区", "1000", "100","1440"],
      //     ["涵江区", "1000", "200","1402"],
      //     ["秀屿区", "1000", "300","500"],
      //     ["总站周边", "1000", "100","803"],
      //     ["仙游区", "1000", "200","450"],
      //     ["总站周边", "1000", "100","803"],
      //     ["仙游区", "1000", "200","450"],
      //   ],
      //   rowNum: 6, //表格行数
      //   headerHeight: 35,
      //   headerBGC: "#0f1325", //表头
      //   oddRowBGC: "#0f1325", //奇数行
      //   evenRowBGC: "#171c33", //偶数行
      //   index: true,
      //   columnWidth: [50],
      //   align: ["center"]
      // }
    };
  },
  props:{
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {},
  mounted() {
    
  },
  methods: {}
};
</script>

<style lang="scss">
#centerRight1 {
  padding: 0.2rem;
  height: 100%;//5.125rem;
  min-width: 2.75rem;
  border-radius: 0.0625rem;
  .bg-color-black {
    height: 100%;//4.8125rem;
    border-radius: 0.125rem;
  }
  .text {
    color: #c3cbde;
  }
  .body-box {
    border-radius: 0.125rem;
    overflow: hidden;
  }
}
</style>