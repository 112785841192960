<template>
  <div id="centerLeft1" style="">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2" style="width: 100%">
        <span style="color: #5cd9e8">
          <icon name="chart-pie"></icon>
        </span>
        <div class="d-flex" style="flex: 1; position: relative">
          <span class="fs-xl text mx-2">地图数据</span>

          <dv-decoration-1
            style="
              width: 1.25rem;
              height: 0.25rem;
              position: relative;
              top: -0.0375rem;
            "
          />
          <span class="fs-xl text2 mx-2" @click="goMap">全屏</span>
        </div>
      </div>
      <div class="d-flex flexDiv" v-if="mapIndex !== 0">
        <span class="fs-xl text mx-2" @click="backFun()">返回上一级</span>
      </div>
      <div class="d-flex jc-center" style="width: 100%; height: 90%">
        <CenterLeft3Chart
          v-on:changes="changes"
          :mapIndex="mapIndex"
          :marker="marker"
          :numTotal="numTotal"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CenterLeft3Chart from "@/components/echart/centerLeft/centerLeft3Chart";
export default {
  data() {
    return {
      mapIndex: 0, // 层级
    };
  },
  props: {
    marker: {
      type: Object,
      default: () => ({}),
    },
    numTotal:{
      type:Number,
      default:500
    }
  },
  components: {
    CenterLeft3Chart,
  },
  methods: {
    goMap() {
      let that = this;
      // console.log('this.$route.marker',this.marker)
      // this.$router.push({name:'map',params:{marker:this.marker}});
      let { href } = this.$router.resolve({
        path: `/map?marker=${(JSON.stringify(this.marker))}`,
      });
      window.open(href, "_blank");
    },
    changes(index) {
      this.mapIndex = index;
      this.$forceUpdate();
    },
    backFun() {
      // console.log("123");
      if (this.mapIndex !== 0) {
        this.mapIndex = this.mapIndex - 1;
        this.$forceUpdate();
      }
    },
  },
};
</script>

<style lang="scss">
#centerLeft1 {
  padding: 0.2rem;
  height: 100%; //5.125rem;
  min-width: 3.75rem;
  border-radius: 0.0625rem;
  position: relative;
  cursor: pointer;
  .bg-color-black {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 100%; //4.8125rem;
    border-radius: 0.125rem;
  }
  .flexDiv {
    position: absolute;
    top: 12%;
    left: 7%;
    z-index: 11111;
    cursor: pointer;
  }
  .text {
    color: #c3cbde;
  }
  .text2 {
    position: absolute;
    top: 0px;
    right: 0px;
  }
  .chart-box {
    margin-top: 0.2rem;
    width: 2.125rem;
    height: 2.125rem;
    .active-ring-name {
      padding-top: 0.125rem;
    }
  }
  .fixed {
    position: fixed;
    bottom: 0px;
    left: 0px;
  }
}
</style>
