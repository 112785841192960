<template>
  <div id="center">
    <div class="up">
      <div
        class="bg-color-black item"
        v-for="(item, index) in titleItem"
        :key="item.title"
        :style="index > 8 ? 'width:33.3%' : ''"
      >
        <p class="ml-3 colorBlue fw-b">{{ item.title }}</p>
        <div>
          <dv-digital-flop
            :config="item.number"
            style="width: 2rem; height: 0.625rem"
          />
        </div>
      </div>
    </div>
    <div class="down" style="margin-top:5px;">
      <div class="ranking bg-color-black">
        <span style="color: #5cd9e8">
          <icon name="align-left"></icon>
        </span>
        <span class="fs-xl text mx-2 mb-1">{{ ranking.title }} </span>
        <dv-scroll-ranking-board
          :config="ranking.ranking"
          style="height: 2.75rem"
        />
      </div>
      <!-- <div class="percent">
        <div
          class="item bg-color-black"
          v-for="(item, index) in rate"
          :key="index"
        >
          <span>{{ item.title }}</span>
          <CenterChart
            :id="item.id"
            :tips="item.tips"
            :colorObj="item.colorData"
          />
        </div>
        
        <div class="water">
          <dv-water-level-pond :config="water" style="height: 1.5rem" />
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
// import CenterChart from "@/components/echart/center/centerChartRate";

export default {
  data() {
    return {
      // water: {
      //   data: [24, 45],
      //   shape: "roundRect",
      //   formatter: "{value}%",
      //   waveNum: 3
      // },
      // 通过率和达标率的组件复用数据
    };
  },
  props: {
    titleItem: {
      type: Array,
      default: () => [],
    },
    ranking: {
      type: Object,
      default: () => ({}),
    },
    rate: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    console.log(this.ranking,"titleItem")
  },
  components: {
    // CenterChart,
    // centerChart1,
    // centerChart2
  },
};
</script>

<style lang="scss" scoped>
#center {
  height: 100%;
  display: flex;
  flex-direction: column;
  .up {
    width: 100%;
    height: 40%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .item {
      border-radius: 0.0625rem;
      padding-top: 0.2rem;
      margin-top: 0.1rem;
      width: 32%;
      // height: 0.875rem;
    }
  }
  .down {
    height: 58%;
    padding: 0.07rem 0.05rem;
    padding-bottom: 0;
    width: 100%;
    display: flex;
    // height: 3.1875rem;
    justify-content: space-between;
    .bg-color-black {
      border-radius: 0.0625rem;
    }
    .ranking {
      padding: 0.125rem;
      width: 100%;
    }
    .percent {
      width: 40%;
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 50%;
        // height: 1.5rem;
        span {
          margin-top: 0.0875rem;
          display: flex;
          justify-content: center;
        }
      }
      .water {
        width: 100%;
      }
    }
  }
}
</style>